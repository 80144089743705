import React from 'react'
import reglamentoConvivencia from '../../docs/convivencia-escolar/NuevoReglamentoDeConvivencia2021.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export const ConvivenciaEscolar = () => {
    return (
        <>
            <h2 className="has-text-white">Convivencia Escolar</h2>
            <p>Dentro de todo establecimiento educativo, es imprescindible contar con un reglamento de convivencia escolar, se hace necesario para que la comunidad educativa, se relacione de forma armónica, tolerante y respetándose mutuamente.</p>
            <p>La creación de este reglamento surge a través de las distintas experiencias del equipo de convivencia y de la realidad vivida en el establecimiento, considerando que las personas son lo más importante dentro de ella.</p>
            <p>El objetivo de este reglamento es facilitar el desarrollo de actividades y las relaciones de las personas que interactúan dentro del establecimiento. Explicar el orden jerárquico, las líneas de autoridad, los canales de comunicación, los derechos, funciones, deberes y compromisos de los diferentes estamentos de un centro educativo; y presentar modos para conciliar intereses, necesidades, asegurar el logro de los objetivos establecidos, dentro de un ambiente armónico, pacífico y productivo.</p>
            <a href={reglamentoConvivencia} target=" _blank" rel="noopener" className="btn btn-dark"><span><FontAwesomeIcon icon={faDownload} /> Ver reglamento completo</span></a>
        </>
    )
}
